import styled from "styled-components";
import LoginInputBox from "./LoginInputBox";
import { TextTerms } from "../assets/InputAssets";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  height: 100vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.secondary};
`;

const Register = styled.u`
  width: 100%;
  text-align: center;
  cursor: pointer;
`;

const Subtext = styled(TextTerms)`
  margin-bottom: 2.375rem;
`;

const DarkSubtext = styled.p`
  color: ${({ theme }) => theme.secondary};
  display: inline;
  font-size: 0.75rem;
`;

function LoginRight() {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <LoginInputBox />
        <Subtext>
          By signing In, you agree to our{" "}
          <DarkSubtext>
            <u>Terms of services</u> & <u>Privacy policy</u>
          </DarkSubtext>
        </Subtext>
        <Register onClick={() => navigate("/register")}>
          Don't have an account? Sign Up
        </Register>
      </Container>
    </>
  );
}

export default LoginRight;
