import styled from "styled-components";
import PlusImg from "../assets/Plus.svg";
import PfpDefault from "../assets/PfpDefault.svg";
import CaretDown from "../assets/Caret Down.svg";
import { NAV_BAR_HEIGHT } from "../contexts/Constants";
import { CreatePitchButton } from "../assets/Buttons";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../contexts/GlobalContext";

const Container = styled.nav`
  height: calc(${NAV_BAR_HEIGHT} - 1.5rem);
  width: calc(100% - 4rem);
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 2rem;
`;

const NavLeft = styled.div`
  height: 100%;
`;

const NavRight = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Page = styled.h2`
  display: flex;
  align-items: center;
  height: 100%;
`;

const UserBox = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const UserPfp = styled.img`
  height: 3rem;
  width: 3rem;
  border-radius: 1.5rem;
  margin-right: 0.5rem;
`;

const UserName = styled.div`
  height: 3rem;
  width: 3.125rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: 0 0.5rem;
`;

const ButtonPlusImg = styled.img`
  padding: 0.1875rem;
`;

function Navbar() {
  // NEED TO CHANGE NAME CAN ADD USER PROFILE OBJECT TO GLOBAL CONTEXT AND SET USERNAME FROM THERE
  // HAVE TO THINK OF A GOOD WAY TO SEND IT WITH TOKEN ON AUTH
  const [username, setUsername] = useState("Name");
  const { setSelectedPageState, setLoggedIn } = useGlobalContext();

  useEffect(() => {
    setUsername("Name");
  }, []);

  return (
    <Container>
      <NavLeft>
        <Page>Dashboard</Page>
      </NavLeft>
      <NavRight>
        <CreatePitchButton onClick={() => setSelectedPageState("add")}>
          <ButtonPlusImg src={PlusImg} alt="Plus" />
          Create New Pitch
        </CreatePitchButton>
        <UserBox
          onClick={() => {
            setLoggedIn({ status: false, token: "" });
            localStorage.removeItem("token");
          }}
        >
          <UserPfp src={PfpDefault} alt="PFP" />
          <UserName>{username}</UserName>
          <img src={CaretDown} alt="Down Caret" />
        </UserBox>
      </NavRight>
    </Container>
  );
}

export default Navbar;
