import { SyntheticEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../assets/Buttons";
import { FormTitle, InputBox, InputLabel } from "../assets/InputAssets";
import axios, { AxiosError } from "axios";
import { BACKEND_URL } from "../contexts/Constants";
import { useGlobalContext } from "../contexts/GlobalContext";

type User = {
  email: string;
  password: string;
};

const CustomButton = styled(Button)`
  width: 100%;
  margin-top: 0.4rem;
  margin-bottom: 1.2rem;
`;

const Form = styled.form`
  margin: 0 auto;
  width: 23.6rem;
  position: relative;
`;

export const doLogin = async (user: { email: string; password: string }) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/login`,
      {
        email: user.email,
        password: user.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

function LoginInputBox() {
  const [user, setUser] = useState<User>({ email: "", password: "" });
  const inputRef = useRef<HTMLInputElement>(null);
  const { setLoggedIn } = useGlobalContext();

  useEffect(() => {
    inputRef.current!.focus();
  }, []);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      const token = await doLogin(user);
      console.log(token);
      setLoggedIn({ status: true, token: token.token });
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(
          `Error ${error.response?.status}: ${error.response?.data.message}`
        );
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormTitle>Get Started Now</FormTitle>
        <InputLabel htmlFor="email">Email</InputLabel>
        <InputBox
          required
          type="email"
          name="email"
          value={user.email}
          onChange={handleChange}
          ref={inputRef}
          placeholder="john.doe@gmail.com"
        />
        <InputLabel htmlFor="password">Password</InputLabel>
        <InputBox
          required
          type="password"
          name="password"
          value={user.password}
          onChange={handleChange}
          placeholder="**********"
        />
        <CustomButton type="submit">Sign In</CustomButton>
      </Form>
    </>
  );
}

export default LoginInputBox;
