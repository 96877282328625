import styled from "styled-components";

export const FormTitle = styled.h3`
  margin-bottom: 2.375rem;
`;

export const TextTerms = styled.h6`
  margin: 0 auto;
  color: ${({ theme }) => theme.grey};
`;

export const InputBox = styled.input`
  height: 1.5rem;
  width: 22rem;
  font-size: 0.9375rem;
  padding: 0.8rem;
  border-radius: 0.75rem;
  border: 0.0625rem solid #d0d3db;
  margin-bottom: 0.8rem;
  outline: none;
`;

export const InputLabel = styled.label`
  font-size: 0.75rem;
  margin-bottom: 0.1875rem;
  display: block;
`;
