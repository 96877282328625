import { useEffect, useState } from "react";
import { Pitch, useGlobalContext } from "../contexts/GlobalContext";
import axios from "axios";
import { BACKEND_URL } from "../contexts/Constants";
import styled from "styled-components";
import Button from "../assets/Buttons";
import PlusIcon from "../assets/Plus.svg";
import { getFormFromPitch } from "../utils/helpers";

const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`;

const HeaderContainer = styled.div`
  height: 3.4375rem;
  padding: 1rem 2rem;
  border-bottom: solid #eeeeee 0.09375rem;
  font-weight: bold;
`;

const Header = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;
const PitchCount = styled.div`
  flex-basis: 4.17%;
`;

const Rep = styled.div`
  flex-basis: 8.33%;
`;

const Date = styled.div`
  flex-basis: 16.67%;
`;

const Time = styled.div`
  flex-basis: 8.33%;
`;

const Dealer = styled.div`
  flex-basis: 25%;
`;

const Phone = styled.div`
  flex-basis: 12.5%;
`;

const State = styled.div`
  flex-basis: 8.33%;
`;

const Status = styled.div`
  flex-basis: 8.33%;
`;

const Action = styled.div`
  flex-basis: 8.33%;
`;

const BodyContainer = styled.div`
  height: 3.4375rem;
  padding: 1rem 2rem;
  border-bottom: solid #eeeeee 0.0625rem;
`;

const Body = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const ActionButton = styled(Button)`
  width: 6rem;
  height: 2.125rem;
  fontweight: normal;
`;

function PagePitches() {
  const {
    formData,
    setFormData,
    selectedBusiness,
    loggedIn,
    setSelectedPageState,
  } = useGlobalContext();
  const [pitches, setPitches] = useState<Pitch[]>([]);

  const handleClickView = (pitch: Pitch) => {
    setFormData(getFormFromPitch(pitch));
    setSelectedPageState("edit");
  };

  const handleClickAdd = (businessName: string) => {
    setFormData({ ...formData, businessName: businessName });
    setSelectedPageState("add");
  };

  useEffect(() => {
    const getPitches = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/pitches`, {
          headers: {
            Authorization: `Bearer ${loggedIn.token}`,
            "Content-Type": "application/json",
          },
          params: {
            businessName: selectedBusiness,
          },
        });
        setPitches(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getPitches();
  }, [selectedBusiness, loggedIn]);
  return (
    <>
      <TitleBox>
        <div
          style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#495469" }}
        >
          Pitches Records
        </div>
        <Button
          style={{
            width: "19.75rem",
            height: "2.5rem",
            fontWeight: "normal",
            fontSize: "1rem",
          }}
          onClick={() => handleClickAdd(selectedBusiness)}
        >
          <img src={PlusIcon} alt="Plus" style={{ paddingRight: "0.5rem" }} />
          Add New Pitch For This Business
        </Button>
      </TitleBox>
      <Container>
        <HeaderContainer>
          <Header>
            <PitchCount>No</PitchCount>
            <Rep>Rep</Rep>
            <Date>Date</Date>
            <Time>Time</Time>
            <Dealer>Dealer</Dealer>
            <Phone>Phone</Phone>
            <State>State</State>
            <Status>Status</Status>
            <Action>Action</Action>
          </Header>
        </HeaderContainer>
        {pitches.map((pitch, index) => {
          const date: Date = new globalThis.Date(pitch.lastModified);
          return (
            <BodyContainer key={index + 1}>
              <Body>
                <PitchCount>{index + 1}</PitchCount>
                <Rep>{pitch.contactName}</Rep>
                <Date>{date.toLocaleDateString("en-au")}</Date>
                <Time>
                  {date.toLocaleTimeString("en-au", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </Time>
                <Dealer>{pitch.businessName}</Dealer>
                <Phone>{pitch.phone}</Phone>
                <State>{pitch.state}</State>
                <Status>{pitch.status}</Status>
                <Action>
                  <ActionButton onClick={() => handleClickView(pitch)}>
                    View
                  </ActionButton>
                </Action>
              </Body>
            </BodyContainer>
          );
        })}
      </Container>
    </>
  );
}

export default PagePitches;
