import styled from "styled-components";
import { STATUS_OPTIONS } from "../contexts/Constants";
import Button from "../assets/Buttons";
import PlusSVG from "../assets/Plus.svg";
import GridCard from "../components/GridCard";
import { useGlobalContext } from "../contexts/GlobalContext";
import { DndProvider, useDrop } from "react-dnd";
import { ItemTypes } from "../utils/itemTypes";
import { HTML5Backend } from "react-dnd-html5-backend";

const Grid = styled.div`
  height: calc(100% - 5.75rem);
  padding: 1rem 0.5rem;
  border-radius: 1.25rem;
  min-width: 8.25rem;
  width: ${100 / STATUS_OPTIONS.length}%;
  background-color: ${({ theme }) => theme.white};
  position: relative;
  font-size: 0.875rem;
  color: #070018;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  justify-content: space-around;
  display: flex;
  gap: 1rem;
`;

const Header = styled.div`
  height: 1.3125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderColorBox = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.25rem;
  display: inline-block;
  margin: 0.5rem;
`;

const HeaderBusinessCount = styled.div`
  color: ${({ theme }) => theme.tertiaryGrey};
  padding-left: 0.625rem;
  display: inline-block;
`;

const HeaderRight = styled.div``;

const GridCardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: calc(100% - 1.3125rem);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0.1875rem;
  }
  &::-webkit-scrollbar-track {
    background: #e2eaf9;
    border-radius: 2.1875rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdc9e2;
    border-radius: 2.1875rem;
  }
`;

const ButtonAdd = styled(Button)`
  width: 1.25rem;
  height: 1.25rem;
`;

// Droppable grid props interface
interface DroppableGridProps {
  status: string;
  children: React.ReactNode;
}

// Define the type for the dragged item
interface DragItem {
  id: string;
  status: string;
}

// Adding Drop functionality to the Grid (columns)
const DroppableGrid = ({ status, children }: DroppableGridProps) => {
  const { moveBusiness } = useGlobalContext();

  const [{ isOver }, drop] = useDrop<DragItem, void, { isOver: boolean }>({
    accept: ItemTypes.BUSINESS,
    drop: (item) => moveBusiness(item, status),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <Grid ref={drop} style={{ backgroundColor: isOver ? "#f0f8ff" : "" }}>
      {children}
    </Grid>
  );
};

function PageGrid() {
  const { setSelectedPageState, businesses, formData, setFormData } =
    useGlobalContext();
  return (
    <Container>
      <DndProvider backend={HTML5Backend}>
        {STATUS_OPTIONS.map((status) => (
          <DroppableGrid key={status.status} status={status.status}>
            <Header>
              <HeaderLeft>
                <HeaderColorBox
                  style={{
                    background: status.color,
                  }}
                ></HeaderColorBox>
                <div>{status.status}</div>
                <HeaderBusinessCount>
                  (
                  {
                    businesses.filter(
                      (business) => business.status === status.status
                    ).length
                  }
                  )
                </HeaderBusinessCount>
              </HeaderLeft>
              <HeaderRight>
                <ButtonAdd
                  onClick={() => {
                    setSelectedPageState("add");
                    setFormData({ ...formData, status: status.status });
                  }}
                >
                  <img src={PlusSVG} alt="Plus" />
                </ButtonAdd>
              </HeaderRight>
            </Header>
            <GridCardBody>
              {businesses
                .filter((business) => business.status === status.status)
                .map((business) => (
                  <GridCard key={business.businessName} business={business} />
                ))}
            </GridCardBody>
          </DroppableGrid>
        ))}
      </DndProvider>
    </Container>
  );
}

export default PageGrid;
