import { SyntheticEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../assets/Buttons";
import { FormTitle, InputBox, InputLabel } from "../assets/InputAssets";
import axios, { AxiosError } from "axios";
import { BACKEND_URL } from "../contexts/Constants";
import { doLogin } from "./LoginInputBox";
import { useGlobalContext } from "../contexts/GlobalContext";

type User = {
  name: string;
  email: string;
  password: string;
  business: string;
};

const CustomButton = styled(Button)`
  width: 100%;
  margin-top: 0.4rem;
  margin-bottom: 1.2rem;
`;

const Form = styled.form`
  margin: 0 auto;
  width: 23.6rem;
  position: relative;
`;

function RegisterInputBox() {
  const [user, setUser] = useState<User>({
    name: "",
    email: "",
    password: "",
    business: "",
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const { setLoggedIn } = useGlobalContext();

  useEffect(() => {
    inputRef.current!.focus();
  }, []);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const doRegister = async (user: User) => {
      const response = await axios.post(
        `${BACKEND_URL}/register`,
        {
          name: user.name,
          email: user.email,
          password: user.password,
          business: user.business,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 201) {
        const token = await doLogin({
          email: user.email,
          password: user.password,
        });

        setLoggedIn({ status: true, token: token.token });
      }
    };
    try {
      await doRegister(user);
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log(error.response);
        alert(error.response?.data.message);
      } else {
        console.log(error);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormTitle>Get Started Now</FormTitle>
        <InputLabel htmlFor="name">Name*</InputLabel>
        <InputBox
          required
          type="text"
          name="name"
          value={user.name}
          onChange={handleChange}
          ref={inputRef}
          placeholder="John Doe"
        />
        <InputLabel htmlFor="email">Email*</InputLabel>
        <InputBox
          required
          type="email"
          name="email"
          value={user.email}
          onChange={handleChange}
          placeholder="john.doe@gmail.com"
        />
        <InputLabel htmlFor="password">Password*</InputLabel>
        <InputBox
          required
          type="password"
          name="password"
          value={user.password}
          onChange={handleChange}
          placeholder="**********"
        />
        <InputLabel htmlFor="business">Business</InputLabel>
        <InputBox
          type="business"
          name="business"
          value={user.business}
          onChange={handleChange}
          placeholder="SimpleSales"
        />
        <CustomButton type="submit">Sign up</CustomButton>
      </Form>
    </>
  );
}

export default RegisterInputBox;
