export const NAV_BAR_HEIGHT = "5.5rem";
export const SIDE_BAR_WIDTH = "15.5rem";
export const TOOL_BAR_HEIGHT = "6.25rem";
export const STATUS_OPTIONS = [
  { status: "Never", color: "#F12727" },
  { status: "Cold", color: "#F18827" },
  { status: "Upcoming", color: "#FFFA74" },
  { status: "Warm", color: "#9747FF" },
  { status: "Almost", color: "#0A84FF" },
  { status: "Success", color: "#46BC4A" },
];
export const sortByOptions = ["Last Modified", "Alphabetical"];
export const ledUsageOptions = ["Rental", "Install", "Past"];
export const dmsOptions = [
  "AutoManager",
  "CDS",
  "CarSwap",
  "DealerSolutions",
  "EasyCars",
  "GumTree",
  "Other",
  "Pentana",
  "TradingPost",
  "Ultimate",
  "Unknown",
  "VirtualYard",
];
export const reminderValues = [
  "1 Hour",
  "1 Day",
  "2 Day",
  "3 Day",
  "1 Week",
  "1 Month",
  "Never",
];
export const BACKEND_URL = "/api";
