import styled from 'styled-components';
import LoginLeft from '../components/LoginLeft';
import LoginRight from '../components/LoginRight';

const Container = styled.div`
  display: flex;
  height: 100vh;
`

function Login() {
  return (
    <Container> 
      <LoginLeft />
      <LoginRight />
    </Container>
  )
}

export default Login;