import styled from "styled-components";
import {
  NAV_BAR_HEIGHT,
  SIDE_BAR_WIDTH,
  TOOL_BAR_HEIGHT,
} from "../contexts/Constants";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { useGlobalContext } from "../contexts/GlobalContext";
import Toolbar from "../components/Toolbar";
import PageGrid from "./PageGrid";
import PageAdd from "./PageAdd";
import PageEdit from "./PageEdit";
import PageList from "./PageList";
import PagePitches from "./PagePitches";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
`;

const Main = styled.div`
  width: calc(100% - ${SIDE_BAR_WIDTH});
`;

const PageContainer = styled.div`
  width: calc(100% - 2rem);
  height: calc(100% - ${NAV_BAR_HEIGHT} - ${TOOL_BAR_HEIGHT} - 2rem);
  padding: 1rem;
`;

function PageLoader() {
  const { gridView, selectedPageState } = useGlobalContext();
  if (selectedPageState === "viewAll" && gridView === true) {
    return <PageGrid></PageGrid>;
  } else if (selectedPageState === "viewAll" && gridView === false) {
    return <PageList></PageList>;
  } else if (selectedPageState === "add") {
    return <PageAdd></PageAdd>;
  } else if (selectedPageState === "edit") {
    return <PageEdit></PageEdit>;
  }
  return <PagePitches></PagePitches>;
}

function Dashboard() {
  const { loggedIn } = useGlobalContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedIn.status === false) {
      navigate("/login");
    }
  }, [navigate, loggedIn]);
  return (
    <Container>
      <Sidebar />
      <Main>
        <Navbar />
        <Toolbar />
        <PageContainer>{PageLoader()}</PageContainer>
      </Main>
    </Container>
  );
}

export default Dashboard;
