import styled from "styled-components";

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  cursor: pointer;
  color: ${({ theme }) => theme.white};
  height: 3.44rem;
  font-weight: bold;
  border: none;
  border-radius: 3.125rem;
`;

interface CustomButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const Button: React.FC<CustomButtonProps> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

const PitchCreateButton = styled(Button)`
  border: none;
  border-radius: 2.25rem;
  width: 12.375rem;
  height: 2.5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
`;

export const CreatePitchButton: React.FC<CustomButtonProps> = ({
  children,
  ...props
}) => {
  return <PitchCreateButton {...props}>{children}</PitchCreateButton>;
};

const ViewButton = styled(Button)<{ $active: boolean }>`
  background-color: ${({ $active, theme }) =>
    $active ? theme.primary : theme.white};
  color: ${({ $active, theme }) =>
    $active ? theme.white : theme.tertiaryGrey};
  width: 6.0625rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  img {
    stroke: ${({ $active, theme }) =>
      $active ? theme.white : theme.tertiaryGrey};
    fill: ${({ $active, theme }) =>
      $active ? theme.white : theme.tertiaryGrey};
  }
`;

interface ToggleButton extends CustomButtonProps {
  active: boolean;
}

export const ViewGridButton: React.FC<ToggleButton> = ({
  children,
  active,
  ...props
}) => {
  return (
    <ViewButton $active={active} {...props}>
      {children}
    </ViewButton>
  );
};

export const ViewListButton: React.FC<ToggleButton> = ({
  children,
  active,
  ...props
}) => {
  return (
    <ViewButton $active={active} {...props}>
      {children}
    </ViewButton>
  );
};

const SidebarButton = styled(Button)<{ $active: boolean }>`
  font-size: 1rem;
  font-weight: normal;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  background-color: ${({ $active, theme }) =>
    $active ? theme.primary : theme.secondary};
`;

export const DashboardSideButton: React.FC<ToggleButton> = ({
  children,
  active,
  ...props
}) => {
  return (
    <SidebarButton $active={active} {...props}>
      {children}
    </SidebarButton>
  );
};

export const AddPitchSideButton: React.FC<ToggleButton> = ({
  children,
  active,
  ...props
}) => {
  return (
    <SidebarButton $active={active} {...props}>
      {children}
    </SidebarButton>
  );
};

export const CancelButton: React.FC<CustomButtonProps> = ({
  children,
  ...props
}) => {
  const ReturnButton = styled(Button)`
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.primary};
    border: solid ${({ theme }) => theme.primary} 0.0625rem;
    width: 8.75rem;
  `;
  return <ReturnButton {...props}>{children}</ReturnButton>;
};

export default Button;
