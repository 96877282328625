import styled from "styled-components";
import quoteMarksSVG from "../“.svg";
import KaneEllis from "../assets/Potrait_Kane_Ellis.webp";

const Container = styled.div`
  background-color: ${({ theme }) => theme.secondary};
  height: 100vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.white};
`;

const Text = styled.h1`
  display: block;
  margin: 2rem auto;
  width: 30rem;
  height: 20.625rem;
  font-weight: normal;
  line-height: 120%;
  color: ${({ theme }) => theme.white};
`;

const QuoteMarks = styled.img`
  width: 5.505rem;
  margin: 0 auto;
`;

const ReferenceAuthorBox = styled.div`
  height: 3.2rem;
  width: 30rem;
  margin: 0 auto;
  display: flex;
  gap: 1.1875rem;
`;

const ReferenceAuthorPFP = styled.img`
  width: 3.2rem;
  border-radius: 1rem;
`;

const ReferenceAuthorTextBox = styled.div`
  width: calc(100% - 4.3875rem);
  margin: 0.1875rem 0;
  line-height: 130%;
`;

const ReferenceAuthorTitle = styled.h4`
  color: ${({ theme }) => theme.primary};
  margin-bottom: 0.1875rem;
`;
const ReferenceAuthorSubTitle = styled.h5`
  color: ${({ theme }) => theme.white};
  font-weight: normal;
`;

function LoginLeft() {
  return (
    <Container>
      <QuoteMarks src={quoteMarksSVG} alt="Quotation marks" />
      <Text>
        SimpleSales CMS helps us keep track of our sales leads much easier.
      </Text>
      <ReferenceAuthorBox>
        <ReferenceAuthorPFP src={KaneEllis} />
        <ReferenceAuthorTextBox>
          <ReferenceAuthorTitle>Kane Ellis</ReferenceAuthorTitle>
          <ReferenceAuthorSubTitle>
            Chief Operations Officer @ CarSwap
          </ReferenceAuthorSubTitle>
        </ReferenceAuthorTextBox>
      </ReferenceAuthorBox>
    </Container>
  );
}

export default LoginLeft;
