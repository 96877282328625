import styled from "styled-components";
import { useGlobalContext } from "../contexts/GlobalContext";
import Button from "../assets/Buttons";

const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
`;

const HeaderContainer = styled.div`
  height: 3.4375rem;
  padding: 1rem 2rem;
  border-bottom: solid #eeeeee 0.09375rem;
  font-weight: bold;
`;

const Header = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;
const PitchCount = styled.div`
  flex-basis: 4.17%;
`;

const Rep = styled.div`
  flex-basis: 8.33%;
`;

const Date = styled.div`
  flex-basis: 16.67%;
`;

const Time = styled.div`
  flex-basis: 8.33%;
`;

const Dealer = styled.div`
  flex-basis: 25%;
`;

const Phone = styled.div`
  flex-basis: 12.5%;
`;

const State = styled.div`
  flex-basis: 8.33%;
`;

const Status = styled.div`
  flex-basis: 8.33%;
`;

const Action = styled.div`
  flex-basis: 8.33%;
`;

const BodyContainer = styled.div`
  height: 3.4375rem;
  padding: 1rem 2rem;
  border-bottom: solid #eeeeee 0.0625rem;
`;

const Body = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const ActionButton = styled(Button)`
  width: 6rem;
  height: 2.125rem;
  fontweight: normal;
`;

function PageList() {
  const { businesses, setSelectedPageState, setSelectedBusiness } =
    useGlobalContext();
  return (
    <Container>
      <HeaderContainer>
        <Header>
          <PitchCount>#</PitchCount>
          <Rep>Rep</Rep>
          <Date>Date</Date>
          <Time>Time</Time>
          <Dealer>Dealer</Dealer>
          <Phone>Phone</Phone>
          <State>State</State>
          <Status>Status</Status>
          <Action>Action</Action>
        </Header>
      </HeaderContainer>
      {businesses.map((business) => {
        const date: Date = new globalThis.Date(business.lastModified);
        return (
          <BodyContainer key={business.businessName}>
            <Body>
              <PitchCount>{business.pitchCount}</PitchCount>
              <Rep>{business.rep}</Rep>
              <Date>{date.toLocaleDateString("en-au")}</Date>
              <Time>
                {date.toLocaleTimeString("en-au", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
              </Time>
              <Dealer>{business.businessName}</Dealer>
              <Phone>{business.phone}</Phone>
              <State>{business.state}</State>
              <Status>{business.status}</Status>
              <Action>
                <ActionButton
                  onClick={() => {
                    setSelectedPageState("viewOne");
                    setSelectedBusiness(business.businessName);
                  }}
                >
                  View
                </ActionButton>
              </Action>
            </Body>
          </BodyContainer>
        );
      })}
    </Container>
  );
}

export default PageList;
