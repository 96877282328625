import { FormData, Pitch } from "../contexts/GlobalContext";

export const getFormFromPitch = (pitch: Pitch): FormData => {
  return {
    id: pitch.id,
    staff: pitch.staff,
    businessName: pitch.businessName,
    contactName: pitch.contactName,
    position: pitch.position,
    contactType: pitch.contactType,
    phone: pitch.phone,
    email: pitch.email,
    website: pitch.website,
    country: pitch.country,
    street: pitch.street,
    city: pitch.city,
    state: pitch.state,
    postCode: pitch.postCode,
    dms: pitch.dms,
    ledUsage: pitch.ledUsage,
    status: pitch.status,
    reminderDuration: pitch.reminderDuration,
    contactSentiment: pitch.contactSentiment,
    note: pitch.note,
  };
};
