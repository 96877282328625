import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { ThemeProvider } from "styled-components";
import { Theme } from "./contexts/Theme";
import GlobalStyle from "./contexts/GlobalStyle";
import Dashboard from "./pages/Dashboard";
import { GlobalProvider } from "./contexts/GlobalContext";

function App() {
  return (
    <Router>
      <GlobalProvider>
        <ThemeProvider theme={Theme}>
          <GlobalStyle />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/*" element={<Navigate to="/login" replace />} />
          </Routes>
        </ThemeProvider>
      </GlobalProvider>
    </Router>
  );
}

export default App;
