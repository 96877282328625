import styled, { useTheme } from "styled-components";
import SearchGlass from "../assets/SearchGlass.svg";
import SortImg from "../assets/SortImg.svg";
import { sortByOptions, TOOL_BAR_HEIGHT } from "../contexts/Constants";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../contexts/GlobalContext";
import { ViewGridButton, ViewListButton } from "../assets/Buttons";

const Container = styled.div`
  padding: 1.5rem 2rem;
  height: calc(${TOOL_BAR_HEIGHT} - 3rem);
  border: solid #e0e0e0 0.03125rem;
  border-style: solid none;
  display: flex;
  justify-content: space-between;
`;

const SearchBox = styled.div`
  height: 3rem;
  width: 28rem;
  position: relative;
`;

const SearchBoxGlass = styled.img`
  position: absolute;
  margin: 0.9375rem 1rem;
  padding: 0.0625rem;
  pointerevents: none;
`;

const SearchBoxInput = styled.input`
  height: calc(100% - 1.5rem);
  width: calc(100% - 6rem);
  padding: 0.75rem 3rem;
  border: solid ${({ theme }) => theme.secondaryGrey} 0.0625rem;
  border-radius: 1.5rem;
`;

const ViewToggle = styled.div`
  width: 13.5625rem;
  border: solid ${({ theme }) => theme.secondaryGrey} 0.0625rem;
  background-color: ${({ theme }) => theme.white};
  border-radius: 3.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToolLeft = styled.div``;

const ToolRight = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const TableSortByBox = styled.div`
  width: auto;
  min-width: 9.375rem;
  position: relative;
  display: flex;
  align-items: center;
`;

const TableSortBy = styled.select`
  color: ${({ theme }) => theme.tertiaryGrey};
  width: 100%;
  font-weight: bold;
  padding: 0 2.75rem;
  height: 100%;
  border: solid ${({ theme }) => theme.secondaryGrey} 0.0625rem;
  border-radius: 2.25rem;
  appearance: none;
  cursor: pointer;
`;

const SortIcon = styled.img`
  position: absolute;
  left: 1.5rem;
  top: 1.125rem;
  pointer-events: none;
`;

const SortDownIcon = styled.svg`
  position: absolute;
  right: 1.5rem;
  top: 1.125rem;
  pointer-events: none;
`;

function Toolbar() {
  const [search, setSearch] = useState("");
  const { selectedSortBy, setSetelectedSortBy, gridView, setGridView } =
    useGlobalContext();
  const theme = useTheme();

  useEffect(() => {
    if (gridView) {
      gridButtonOn();
      listButtonOff();
    } else {
      gridButtonOff();
      listButtonOn();
    }
  }, [gridView]);

  const gridButtonOn = () => {};

  const gridButtonOff = () => {};

  const listButtonOn = () => {};

  const listButtonOff = () => {};

  return (
    <Container>
      <ToolLeft>
        <SearchBox>
          <SearchBoxGlass src={SearchGlass} alt="Search Glass Icon" />

          <SearchBoxInput
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </SearchBox>
      </ToolLeft>
      <ToolRight>
        <TableSortByBox>
          <SortIcon src={SortImg} alt="Sort Icon" />
          <TableSortBy
            value={selectedSortBy}
            onChange={(option) => {
              setSetelectedSortBy(option.target.value);
              console.log(option.target.value);
            }}
          >
            <option value={""} disabled>
              Sort By
            </option>
            {sortByOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </TableSortBy>
          <SortDownIcon
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8 7.93129L9.83434 12.897L4.86866 7.93129L6.00002 6.79988L9.83434 10.6342L13.6687 6.79988L14.8 7.93129Z"
              fill={theme.tertiaryGrey}
            />
          </SortDownIcon>
        </TableSortByBox>
        <ViewToggle>
          <ViewListButton active={!gridView} onClick={() => setGridView(false)}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8333 7.16667V2.16667H2.16667V7.16667H13.8333ZM13.8333 8.83333H2.16667V13.8333H13.8333V8.83333ZM1.33333 0.5H14.6667C15.1269 0.5 15.5 0.8731 15.5 1.33333V14.6667C15.5 15.1269 15.1269 15.5 14.6667 15.5H1.33333C0.8731 15.5 0.5 15.1269 0.5 14.6667V1.33333C0.5 0.8731 0.8731 0.5 1.33333 0.5Z"
                fill={!gridView ? theme.white : theme.tertiaryGrey}
              />
            </svg>
            List
          </ViewListButton>
          <ViewGridButton active={gridView} onClick={() => setGridView(true)}>
            <svg
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.33317 4.66667H15.6665V2.16667H2.33317V4.66667ZM10.6665 13.8333V6.33333H7.33317V13.8333H10.6665ZM12.3332 13.8333H15.6665V6.33333H12.3332V13.8333ZM5.6665 13.8333V6.33333H2.33317V13.8333H5.6665ZM1.49984 0.5H16.4998C16.9601 0.5 17.3332 0.8731 17.3332 1.33333V14.6667C17.3332 15.1269 16.9601 15.5 16.4998 15.5H1.49984C1.0396 15.5 0.666504 15.1269 0.666504 14.6667V1.33333C0.666504 0.8731 1.0396 0.5 1.49984 0.5Z"
                fill={gridView ? theme.white : theme.tertiaryGrey}
              />
            </svg>
            Grid
          </ViewGridButton>
        </ViewToggle>
      </ToolRight>
    </Container>
  );
}

export default Toolbar;
