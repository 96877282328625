import styled from "styled-components";
import LoginLeft from "../components/LoginLeft";
import RegisterRight from "../components/RegisterRight";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

function Register() {
  return (
    <Container>
      <LoginLeft />
      <RegisterRight />
    </Container>
  );
}

export default Register;
